// お知らせの一覧

<template lang="pug">
el-card.member-informations
  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る
  information-list
</template>

<script>
import InformationList from '@/components/information-list'

export default {
  components: {
    InformationList,
  },
}
</script>

<style lang="sass" scoped>
.member-informations
  min-height: 200px
</style>
